import BulletIcon from '../../Assets/bullet-point.png'

const contentDetails = [
  {
    category: "Sampurna",
    id:10000,
    content: [
      {
        id: "10001",
        linkTitle: "Sampurna Group Website",
        link: "https://sampurna.co.in/",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "10002",
        linkTitle: "Sampurna Skills Website",
        link: "https://sampurnaskills.co.in/",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "10003",
        linkTitle: "Sampurna Finance Website",
        link: "https://sampurnafinance.co.in/",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "10004",
        linkTitle: "STEP Website",
        link: "https://stepindia.in/",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "10005",
        linkTitle: "Ticket Management System",
        link: "https://tms.sampurna.co.in/admin/",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "10006",
        linkTitle: "BIJLI Web",
        link: "https://step.bijliftt.com/Login.aspx",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "10007",
        linkTitle: "BIJLI App",
        link: "http://bijliserver55.bijliftt.com:3006/step.apk",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "10008",
        linkTitle: "OHIM",
        link: "https://ohim.sampurna.co.in/snipeit/public/login",
        icon: BulletIcon,
        downloadable:"no",
      },
    ],
  },
  {
    category: "ESAF SFB",
    id:20000,
    content: [
      {
        id: "20001",
        linkTitle: "Br.Net",
        link: "https://intraweb.esafbank.com/brnet/login.html",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "20002",
        linkTitle: "Servosys",
        link: "https://intraweb.esafbank.com/istreams/client",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "20003",
        linkTitle: "BC EWeb",
        link: "https://bceweb.esafbank.org/",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "20004",
        linkTitle: "FIS",
        link: "https://fisindia.service-now.com/esaf",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "20005",
        linkTitle: "IKIGAI",
        link: "https://ikigai.esafbank.com/#/",
        icon: BulletIcon,
        downloadable:"no",
      },
    ],
  },
  {
    category: "Jana SFB",
    id:30000,
    content: [
      {
        id: "30001",
        linkTitle: "Sfdc",
        link: "https://jana.my.salesforce.com/home/home.jsp",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "30002",
        linkTitle: "FosR1",
        link: "https://fos.janabank.com/fos/loginauth",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "30003",
        linkTitle: "FosR2",
        link: "https://fos.janabank.com/fosr2/loginauth",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "30004",
        linkTitle: "Helpdesk",
        link: "https://helpdesk.janabank.com/",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "30005",
        linkTitle: "AGLPS",
        link: "https://aglps-fos.janabank.com:7080/AGLPS/",
        icon: BulletIcon,
        downloadable:"no",
      },
    ],
  },
  {
    category: "Avanti SFB",
    id:40000,
    content: [
      {
        id: "40001",
        linkTitle: "Disbursement Report",
        link: "https://avantidash-dot-indihood-prod-in.appspot.com/filters/edit/%2Fview%2FY8wvONJor0",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "40002",
        linkTitle: "Reject Report",
        link: "https://avantidash-dot-indihood-prod-in.appspot.com/filters/edit/%2Fview%2FdSPKbJ8PgY",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "40003",
        linkTitle: "Loan no",
        link: "https://avantidash-dot-indihood-prod-in.appspot.com/filters/edit/%2Fview%2FZJczWs4AQs",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "40004",
        linkTitle: "Demand Collection Report",
        link: "https://avantidash-dot-indihood-prod-in.appspot.com/filters/edit/%2Fview%2FCFgIcWYOhT",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "40005",
        linkTitle: "Submission DateWise Report",
        link: "https://avantidash-dot-indihood-prod-in.appspot.com/filters/edit/%2Fview%2F5GRltuam2z",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "40006",
        linkTitle: "Wallet Collection Report",
        link: "https://avantidash-dot-indihood-prod-in.appspot.com/filters/edit/%2Fview%2FCJtGlP6KGN",
        icon: BulletIcon,
        downloadable:"no",
      }
    ],
  },
  {
    category: "VT",
    id:50000,
    content: [
      {
        id: "50001",
        linkTitle: "EIS",
        link: "https://eisdigital.com/cloud/login",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "50002",
        linkTitle: "EIS Mobile App",
        link: 'https://www.clp.sampurna.co.in/clp/files/EIS.apk',
        icon: BulletIcon,
        downloadable:"yes",
      }
    ],
  },
  {
    category: "HRMS",
    id:60000,
    content: [
      {
        id: "60001",
        linkTitle: "Octane ESSPortal",
        link: "https://sampurna.octane-hrms.com/ESSPortal/logIn.aspx",
        icon: BulletIcon,
        downloadable:"no",
      }
    ],
  },
  {
    category: "Accounts",
    id:70000,
    content: [
      {
        id: "70001",
        linkTitle: "Tally",
        link: "http://220.158.165.199/",
        icon: BulletIcon,
        downloadable:"no",
      }
    ],
  },
  {
    category: "Audit Module",
    id:80000,
    content: [
      {
        id: "80001",
        linkTitle: "Audit 360",
        link: "https://sampurna.audit360.solutions/",
        icon: BulletIcon,
        downloadable:"no",
      }
    ],
  },
  {
    category: "Sampurna Management Tool",
    id:90000,
    content: [
      {
        id: "90001",
        linkTitle: "WebCore Management System",
        link: "https://wms.sampurna.co.in/",
        icon: BulletIcon,
        downloadable:"no",
      }
    ],
  },
  {
    category: "Federal Bank",
    id:100000,
    content: [
      {
        id: "100001",
        linkTitle: "FEDmi ERP",
        link: "https://fedmipro.federalbank.co.in/Fedmi/",
        icon: BulletIcon,
        downloadable:"no",
      },
      {
        id: "100002",
        linkTitle: "FEDmi App",
        link:'https://www.clp.sampurna.co.in/clp/files/Femi_Live_v_1.2.7_03052024.apk',
        icon: BulletIcon,
        downloadable:"yes",
      }
    ],
  },
  {
    category: "Microsoft Account",
    id:110000,
    content: [
      {
        id: "110001",
        linkTitle: "Microsoft 365",
        link: "https://www.office.com/",
        icon: BulletIcon,
        downloadable:"no",
      }
    ],
  }
];

export default contentDetails;
